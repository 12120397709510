import { CORPORATION } from 'admin/define/apiMessages/corporation';
import { MEMBER } from 'admin/define/apiMessages/member';
import { SAML } from 'admin/define/apiMessages/saml';
import { SERVICE } from 'admin/define/apiMessages/service';
import { TENANT_USERS } from 'admin/define/apiMessages/tenantUsers';
import { USER } from 'admin/define/apiMessages/user';
import { USER_POOL } from 'admin/define/apiMessages/userPool';

export const API_MESSAGES = {
  ...USER_POOL,
  ...SERVICE,
  ...SAML,
  ...MEMBER,
  ...USER,
  ...TENANT_USERS,
  ...CORPORATION,
} as const;
