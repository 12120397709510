import { TRANSACTION_MAIL_PERSONAL_TYPE } from 'admin/define/idPoolConsumer/transactionMail';
import { useAdminAuth } from 'admin/hooks/useAdminAuth';
import { useUserCorporationInfo } from 'admin/hooks/user/useUserCorporationInfo';
import { useIdPoolConsumerUniikey } from 'admin/hooks/userPool/useIdPoolConsumerUniikey';
import { Admin } from 'admin/pages';
import { IdPoolConsumer } from 'admin/pages/idPoolConsumer';
import { Attribute } from 'admin/pages/idPoolConsumer/attribute';
import { AttributeGroup } from 'admin/pages/idPoolConsumer/attribute/group';
import { FormGroup } from 'admin/pages/idPoolConsumer/formGroup';
import { Detail } from 'admin/pages/idPoolConsumer/formGroup/detail';
import { IdPoolConsumerIndex } from 'admin/pages/idPoolConsumer/Index/index';
import { Master } from 'admin/pages/idPoolConsumer/master';
import { Code } from 'admin/pages/idPoolConsumer/master/code';
import { Newsletter } from 'admin/pages/idPoolConsumer/newsletter';
import { Terms } from 'admin/pages/idPoolConsumer/terms';
import { TermsIndex } from 'admin/pages/idPoolConsumer/terms/index/index';
import { Log } from 'admin/pages/idPoolConsumer/terms/log';
import { TransactionMail } from 'admin/pages/idPoolConsumer/transactionMail';
import { TransactionMailEdit } from 'admin/pages/idPoolConsumer/transactionMail/transactionMailEdit';
import { TransactionMails } from 'admin/pages/idPoolConsumer/transactionMail/transactionMails';
import { TransactionMailSetting } from 'admin/pages/idPoolConsumer/transactionMail/transactionMailSetting';
import { UserInfo } from 'admin/pages/idPoolConsumer/userInfo';
import { JsonPath } from 'admin/pages/jsonPath';
import { JsonPathExample } from 'admin/pages/jsonPathExample';
import { SAML } from 'admin/pages/saml';
import { Service } from 'admin/pages/service';
import { Attribute as ServiceAttribute } from 'admin/pages/service/attribute';
import { ServiceIndex } from 'admin/pages/service/Index/index';
import { Newsletter as ServiceNewsletter } from 'admin/pages/service/newsletter';
import { Setting as ServiceSetting } from 'admin/pages/service/setting';
import { ServiseRule } from 'admin/pages/service/terms';
import { TenantUsers } from 'admin/pages/tenantUsers';
import { Users } from 'admin/pages/users';
import { TransactionMailPersonalType } from 'admin/types/userPool/transactionMail';
import { FC, useMemo } from 'react';
import {
  Navigate,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';

const redirectPath = '/admin';

// 組織管理画面アクセス制御処理HOC
const withAuthCheckRoutingControl = (
  WrappedComponent: React.ComponentType,
  redirect: string,
  menuName: string,
): FC => {
  const WithAuthCheckRoutingControl: FC = (props) => {
    const { isAllowedMenuPermission } = useAdminAuth();
    // アクセスが許可されていない場合は設定されているリダイレクトへ遷移させる
    if (!isAllowedMenuPermission(menuName)) return <Navigate to={redirect} />;

    return <WrappedComponent {...props} />;
  };

  return WithAuthCheckRoutingControl;
};

const withUniikeyAuthCheckRouter = (
  WrappedComponent: React.ComponentType,
  pathName: string,
) => {
  const WithUniikeyAuthCheckRouter: FC = (props) => {
    const { isShowUserPool } = useUserCorporationInfo();

    // 利用種別IDがuniikeyか判定
    const { isIdPoolConsumerUniikey } = useIdPoolConsumerUniikey();
    const params = useParams();

    const idPoolConsumerId = useMemo(
      () => params.idPoolConsumerId ?? '',
      [params],
    );

    // ユーザープールの権限があるか
    const isShowMenu = useMemo<boolean>(
      () => isShowUserPool(idPoolConsumerId),
      [idPoolConsumerId, isShowUserPool],
    );

    if (!isIdPoolConsumerUniikey || !isShowMenu) {
      return <Navigate to={pathName} />;
    }

    return <WrappedComponent {...props} />;
  };

  return WithUniikeyAuthCheckRouter;
};

const withTransactionMailTypeCheckRouter = (
  WrappedComponent: React.ComponentType,
  pathName: string,
) => {
  const WithTransactionMailTypeCheckRouter: FC = (props) => {
    const location = useLocation();
    const params = useParams();
    const transactionMailType = useMemo<TransactionMailPersonalType>(
      () => params?.transactionMailType as TransactionMailPersonalType,
      [params],
    );

    // transactionMail TOPページではmailTypeのチェックを行わない
    if (
      !location.pathname.endsWith('/transactionMail') &&
      !TRANSACTION_MAIL_PERSONAL_TYPE.includes(transactionMailType)
    ) {
      return <Navigate to={pathName} />;
    }

    // 元のHOCをラップして適用
    const ExtendsUniikeyAuthHOC = withUniikeyAuthCheckRouter(
      WrappedComponent,
      pathName,
    );

    return <ExtendsUniikeyAuthHOC {...props} />;
  };

  return WithTransactionMailTypeCheckRouter;
};

// HOCを適用する
const AdminUsersWithAuthCheckRouting = withAuthCheckRoutingControl(
  Users,
  redirectPath,
  'users',
);
const AdminTenantUsersWithAuthCheckRouting = withAuthCheckRoutingControl(
  TenantUsers,
  redirectPath,
  'tenantUsers',
);
const AdminSamlWithAuthCheckRouting = withAuthCheckRoutingControl(
  SAML,
  redirectPath,
  'saml',
);
const AdminIdPoolConsumerWithAuthCheckRouting = withAuthCheckRoutingControl(
  IdPoolConsumer,
  redirectPath,
  'idPoolConsumer',
);
// const AdminMemberWithAuthCheckRouting = withAuthCheckRoutingControl(Member, redirectPath, 'member');
const AdminServiceWithAuthCheckRouting = withAuthCheckRoutingControl(
  Service,
  redirectPath,
  'service',
);

const AdminRuleWithUniikeyAuthCheckRouting = withUniikeyAuthCheckRouter(
  Terms,
  redirectPath,
);

const AdminRuleWithTransactionMailTypeRouting =
  withTransactionMailTypeCheckRouter(TransactionMail, redirectPath);

export const AdminRoutes: RouteObject[] = [
  // {  開発確認用
  //   path: 'sample',
  //   element: <Sample />,
  // },
  {
    path: '',
    element: <Admin />,
  },

  {
    path: 'users',
    element: <AdminUsersWithAuthCheckRouting />,
  },
  {
    path: 'tenantUsers',
    element: <AdminTenantUsersWithAuthCheckRouting />,
  },
  {
    path: 'saml',
    element: <AdminSamlWithAuthCheckRouting />,
  },
  // TODO: 4月以降対応
  // {
  //   path: 'member',
  //   children: [
  //     { path: '', element: <Member /> },
  //     {
  //       path: ':memberId',
  //       children: [
  //         {
  //           path: '',
  //           element: <MemberDetail />,
  //         },
  //         {
  //           path: 'log',
  //           element: <MemberLog />,
  //         },
  //       ],
  //     },
  //     { path: 'service/:serviceId', element: <MemberService /> },
  //   ],
  // },
  // {
  //   path: 'setting',
  //   element: <Setting />,
  //   children: [{ path: 'user', element: <SettingUser /> }],
  // },
  {
    path: 'idPoolConsumer',
    element: <AdminIdPoolConsumerWithAuthCheckRouting />,
    children: [
      { path: '', element: <IdPoolConsumerIndex /> },
      { path: ':idPoolConsumerId/attribute', element: <Attribute /> },
      {
        path: ':idPoolConsumerId/attribute',
        children: [
          {
            path: '',
            element: <Attribute />,
          },
          {
            path: 'group',
            element: <AttributeGroup />,
          },
        ],
      },
      {
        path: ':idPoolConsumerId/jsonPath',
        element: <JsonPath />,
      },
      { path: ':idPoolConsumerId/userInfo', element: <UserInfo /> },
      {
        path: ':idPoolConsumerId/master',
        children: [
          {
            path: '',
            element: <Master />,
          },
          {
            path: ':masterId/code',
            element: <Code />,
          },
        ],
      },
      {
        path: ':idPoolConsumerId/formGroup',
        children: [
          {
            path: '',
            element: <FormGroup />,
          },
          {
            path: 'detail/',
            element: <Detail />,
            children: [
              {
                path: ':formGroupId/',
                element: <Detail />,
              },
            ],
          },
        ],
      },
      {
        path: ':idPoolConsumerId/terms',

        element: <AdminRuleWithUniikeyAuthCheckRouting />,
        children: [
          { path: '', element: <TermsIndex /> },
          { path: ':termId/log', element: <Log /> },
        ],
      },
      {
        path: ':idPoolConsumerId/newsletter',
        element: <Newsletter />,
      },
      // {
      //   path: ':idPoolConsumerId/rule',
      //   children: [
      //     {
      //       path: '',
      //       element: <Rule />,
      //     },
      //     {
      //       path: ':logId/log',
      //       element: <Log />,
      //     },
      //   ],
      // },
      {
        path: ':idPoolConsumerId/transactionMail',
        element: <AdminRuleWithTransactionMailTypeRouting />,
        children: [
          {
            path: '',
            element: <TransactionMailSetting />,
          },
          {
            path: ':transactionMailType',
            element: <TransactionMails />,
          },
          {
            path: ':transactionMailType/wallEditor/',
            element: <TransactionMailEdit />,
            children: [
              {
                path: ':transactionMailId',
                element: <TransactionMailEdit />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'service',
    element: <AdminServiceWithAuthCheckRouting />,
    children: [
      { path: '', element: <ServiceIndex /> },
      { path: 'setting', element: <ServiceSetting /> },
      {
        path: ':tenantId/:userPoolChildId/attribute',
        element: <ServiceAttribute />,
      },
      {
        path: ':tenantId/:userPoolChildId/newsletter',
        element: <ServiceNewsletter />,
      },
      { path: ':tenantId/:userPoolChildId/terms', element: <ServiseRule /> },
    ],
  },

  {
    path: 'jsonPathExample',
    element: <JsonPathExample />,
  },
];
